import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'

export function useContactDetailTypes() {
    const store = useStore()
    const contactDetailTypes = computed(() => store.getters.contactDetailTypes)

    const getData = () => {
        store.dispatch('fetchContactDetailTypes')
    }

    onMounted(() => {
        if (contactDetailTypes.value && contactDetailTypes.value.length) {
            return
        }

        getData()
    })

    return { contactDetailTypes, getData }
}
